import React from "react";
import LayoutIframe from "../layouts/LayoutIframe";
import LayoutLocalized from "../layouts/LayoutLocalized";
import Enterprise from "../components/Paywalls/Enterprise";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { PageContext } from "../store/context/page-context";
import { graphql } from "gatsby";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import Seo from "../components/Seo";
import useEventFromExt from "../hooks/useEventFromExt";

const EnterpriseTemplate = ({ pageContext }) => {
  const { isIframe, i18n, language, paywall } = pageContext;
  const { t } = useTranslation();
  const { software } = useEventFromExt();
  const addParams = {
    ...(software ? { software } : {}),
    ...((software && paywall) ? { paywall: encodeURIComponent(paywall) } : {}),
  };
  const linkExtra = new URLSearchParams(addParams).toString();
  return (
    <PageContext.Provider
      value={{
        translate: t,
        pathname: "/pricing-pro-enterprise",
        isDeMode: language === "de",
        isJpMode: language === "ja",
        isIframeMode: isIframe,
        linkExtra,
      }}
    >
      {isIframe ? (
        <LayoutIframe>
          <Enterprise context={pageContext} />
        </LayoutIframe>
      ) : (
        <LayoutLocalized>
          <Seo
            title={t("title")}
            description="Use AMZScout and make Amazon product research easy with accurate research tools and seller software: AMZScout PRO Chrome Extension, Product Database, Keyword Search and more. Analyze niches and find the right product to make money. Start your free trial."
            page={i18n.path}
            manifest="browserconfig.xml"
            ogImg={OgImg}
          />
          <Enterprise context={pageContext} />
        </LayoutLocalized>
      )}
    </PageContext.Provider>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { eq: "enterprise" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default EnterpriseTemplate;
